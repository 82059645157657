import React, { useEffect } from 'react';

const NotFound = () => {
  useEffect(() => {
    window.location = "/";
  }, []);

  return (<></>);
}

export default NotFound;
